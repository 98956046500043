<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-toolbar-title><router-link to="/" tag="span" exact :style="{ cursor: 'pointer'}">
              Актеры озвучки
            </router-link></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="logout" v-if="loggedIn" class="mr-3"><v-icon>fa-sign-out-alt</v-icon></v-btn>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  computed: {
    loggedIn: function() {
      return this.$store.getters.loggedIn;
    },
  },
  methods: {
    logout: function() {
      this.$store.dispatch("logoutUser").then(() => {
        this.$router.push("/login");
      });
    },
  },
   created: function () {
    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch("logoutUser")
        }
        throw err;
      });
    });
  }
};
</script>
