import { axiosBase } from '@/api/axios-base';

const state = {
  languages: [],
  dialects: [],
  countries: [],
  currencies: []
};
const getters = {
  LANGUAGE: (state) => {
    return state.languages
  },
  DIALECT: (state) => {
    return state.dialects
  },
  COUNTRY: (state) => {
    return state.countries
  },
  CURRENCY: (state) => {
    return state.currencies
  },
};
const mutations = {
  SET_LANGUAGES: (state, payload) => {
    state.languages = payload;
  },
  SET_DIALECTS: (state, payload) => {
    state.dialects = payload;
  },
  SET_COUNTRIES: (state, payload) => {
    state.countries = payload;
  },
  SET_CURRENCIES: (state, payload) => {
    state.currencies = payload;
  },
};
const actions = {
  GET_FILLERS: async (context) => {
    let { data } = await axiosBase.get(
      "/api/v1/fillers/"
    );
    context.commit("SET_LANGUAGES", data.language);

    context.commit("SET_DIALECTS", data.dialect);

    context.commit("SET_COUNTRIES", data.country);

    context.commit("SET_CURRENCIES", data.currency);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
