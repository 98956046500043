import { axiosBase } from '@/api/axios-base';

const state = {
  personsList: [],
  filteredPersons: []
};
const getters = {
  ACTORS: (state) => {
    return state.filteredPersons.length == 0
    ? state.personsList
    : state.filteredPersons;
  },
};
const mutations = {
  SET_PERSONS_LIST: (state, payload) => {
    state.personsList = payload;
  },
  SET_FILTER: (state, payload) => {
    state.filteredPersons = payload;
  },
  DROP_FILTER: (state) => {
    state.filteredPersons = [];
  },
};
const actions = {
  GET_PERSONS_LIST: async (context) => {
    let { data } = await axiosBase.get(
      "/api/v1/actors/"
    );
    context.commit("SET_PERSONS_LIST", data);
  },
  FILTER: async (context,payload) => {
    state.filteredPersons = state.personsList;
    for (var key in payload) {
      if (payload[key] !== "") {
        var filtr;
        if (key === "gender"){
          filtr = state.filteredPersons.filter((actor) =>
            actor.gender === payload[key]
          )
          context.commit("SET_FILTER", filtr);
        }
        if (key === "language"){
          filtr = state.filteredPersons.filter((actor) =>
            actor.language.language === payload[key]
          )
          context.commit("SET_FILTER", filtr);
        }
        if (key === "dialect"){
          filtr = state.filteredPersons.filter((actor) =>
            actor.dialect.dialect === payload[key]
          )
          context.commit("SET_FILTER", filtr);
        }
        if (key === "country"){
          filtr = state.filteredPersons.filter((actor) =>
            actor.country.country === payload[key]
          )
          context.commit("SET_FILTER", filtr);
        }
      }
    }
    console.log(filtr);

  },
  DROP: ({ commit }) => {
    commit("DROP_FILTER");
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
