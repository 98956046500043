import { axiosBase } from '@/api/axios-base';

const state = {
  selectedActor: [],
};
const getters = {
  SELECTED_ACTOR: (state) => {
    return state.selectedActor;
  },
};
const mutations = {
  SET_SELECTED_ACTOR: (state, payload) => {
    state.selectedActor = payload;
  },
};
const actions = {
  GET_SELECTED_ACTOR: async (context,id) => {
    let { data } = await axiosBase.get(
      "/api/v1/actors/" + id
    );
    context.commit("SET_SELECTED_ACTOR", data);
  },
  SAVE_EDIT: async(context,actor) => {
    let { data } = await axiosBase.post(
      "api/v1/actors/" + actor.site_id, actor
    )
    console.log(data)
    context.commit("SET_SELECTED_ACTOR", actor)
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
