import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import person from "./modules/person";
import personsList from "./modules/persons";
import langProp from "./modules/langProp";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    auth,
    person,
    personsList,
    langProp
  }
});
